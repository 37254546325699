import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { DeactivateGoogleAccount } from "../../API/Deactivate/UserDeactivate";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";

const ConformDeactivate = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  console.log("params", params);
  const _id = params.get("_id");
  console.log("id", _id);
  const deactivateAccount = async () => {
    const res = await DeactivateGoogleAccount(_id);
    console.log("res is coming this on adding id ", res);
    if (res.status) {
      window.location = "/";
    }
  };

  useEffect(() => {
    deactivateAccount();
  }, [_id]);

  return <Spin size="large" />;
};

export default ConformDeactivate;
