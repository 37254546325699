import React, { useEffect, useState } from "react";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { addTime, timeAvail } from "./ListYourBussiness/TimeSlotsAvail";
import { OPTIONS } from "./TimeSlot";
import TimeSlotsAvail from "./TimeSlotsAvail";
// import { timeAvail } from "./ListYourBussiness/TimeSlotsAvail";
import dateFormat from "dateformat";

const CalenderCus = (props) => {
  const [show, SetShow] = useState(false);
  if (props.preDefined) {
    addTime(props.preDefined);
  }
  const [curDate, setCurDate] = useState(12);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setCurrentMonth(new Date().getMonth());
    setCurrentYear(new Date().getFullYear());
  }, []);

  const filteredOptionss = OPTIONS.filter((o) => !selectedItems.includes(o));

  const today = new Date();
  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => prevMonth - 1);
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => prevMonth + 1);
  };
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  const timeHanlder = (time, date) => {
    const index = timeAvail.findIndex(
      (b) => dateFormat(b.date, "isoDate") === date
    );
    if (index !== -1) {
      timeAvail[index].time = time;
      setSelectedItems(time);
    } else {
      timeAvail.push({
        time: time,
        date: selectedDate,
        closed: false,
      });
      setSelectedItems(time);
    }
  };
  const changeShowHandler = () => {
    SetShow(false);
  };
  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  const changeDate = (dayNumber) => {
    setCurDate(dayNumber);
    const dates = new Date(`${currentYear}-${currentMonth + 1}-${dayNumber}`);
    dates.setHours(0, 0, 0, 0);
    const date = dateFormat(dates, "isoDate");
    const checkTime = timeAvail.findIndex(
      (d) => dateFormat(d.date, "isoDate") === date
    );
    if (checkTime !== -1) {
      setSelectedItems(timeAvail[checkTime].time);
    } else {
      setSelectedItems([]);
    }

    setSelectedDate(date);
  };

  const closeTimeHandler = (value, date) => {
    const index = timeAvail.findIndex(
      (b) => dateFormat(b.date, "isoDate") === date
    );
    if (index !== -1) {
      timeAvail[index].closed = !timeAvail[index].closed;
      timeAvail[index].time = [];
      setSelectedItems([]);
    } else {
      timeAvail.push({
        time: [],
        date: selectedDate,
        closed: false,
      });
      setSelectedItems([]);
    }
  };

  return (
    <>
      {show ? (
        <TimeSlotsAvail changeShow={changeShowHandler} date={selectedDate} />
      ) : (
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center my-2">
            <button onClick={handlePrevMonth} className="button-calender">
              <CaretLeftOutlined style={{ fontSize: "20px" }} />
            </button>
            <div className="text-center">{`${new Date(
              currentYear,
              currentMonth
            ).toLocaleDateString("en-US", {
              month: "long",
            })} ${currentYear}`}</div>
            <button onClick={handleNextMonth} className="button-calender">
              <CaretRightOutlined style={{ fontSize: "20px" }} />
            </button>
          </div>
          <table className="w-100 text-center ">
            <thead>
              <tr>
                <th>Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
              </tr>
            </thead>
            <tbody>
              {Array(Math.ceil((firstDayOfMonth + daysInMonth) / 7))
                .fill()
                .map((_, weekIndex) => (
                  <tr key={weekIndex}>
                    {Array(7)
                      .fill()
                      .map((_, dayIndex) => {
                        const dayNumber =
                          weekIndex * 7 + dayIndex + 1 - firstDayOfMonth;
                        return (
                          <td
                            key={dayIndex}
                            onMouseLeave={() => setCurDate(null)}
                          >
                            {dayNumber > 0 && dayNumber <= daysInMonth && (
                              <div className="position-relative">
                                <div
                                  onMouseEnter={() => changeDate(dayNumber)}
                                  // onClick={() => openHandler(dayNumber)}
                                  className={
                                    today.getMonth() + 1 < currentMonth ||
                                    today.getMonth() > currentMonth ||
                                    today.getDate() + 30 < curDate ||
                                    (today.getDate() >= curDate &&
                                      today.getMonth() === currentMonth)
                                      ? "calender-date-not-hover "
                                      : "calender-date"
                                  }
                                >
                                  {today.getMonth() + 1 < currentMonth ||
                                  today.getMonth() > currentMonth ||
                                  today.getDate() + 30 < curDate ||
                                  (today.getDate() >= curDate &&
                                    today.getMonth() === currentMonth) ? (
                                    <div className="calender-disable cursor ">
                                      {dayNumber}
                                    </div>
                                  ) : (
                                    <div className=" cursor">{dayNumber}</div>
                                  )}
                                </div>
                                {curDate === dayNumber
                                  ? today.getMonth() + 1 < currentMonth ||
                                    today.getMonth() > currentMonth ||
                                    today.getDate() + 30 < curDate ||
                                    (today.getDate() >= curDate &&
                                      today.getMonth() === currentMonth) || (
                                      <div
                                        className="position-absolute rounded"
                                        style={{
                                          backgroundColor: "gray",
                                          zIndex: 10,
                                          height: "150px",
                                          width: "300px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        <div className="text-left">
                                          <div className=" text-left mt-1">
                                            <input
                                              type="checkbox"
                                              name="checkboxName"
                                              id="checkboxName"
                                              className="mx-3 text-center"
                                              onChange={(e) =>
                                                closeTimeHandler(
                                                  e.target.value,
                                                  selectedDate
                                                )
                                              }
                                              checked={
                                                timeAvail.find(
                                                  (tim) =>
                                                    dateFormat(
                                                      tim.date,
                                                      "isoDate"
                                                    ) === selectedDate &&
                                                    tim.closed === false
                                                )
                                                  ? false
                                                  : true
                                              }
                                            />
                                            <label htmlFor="checkboxName">
                                              Closed
                                            </label>
                                          </div>
                                          {timeAvail.find(
                                            (tim) =>
                                              dateFormat(
                                                tim.date,
                                                "isoDate"
                                              ) === selectedDate &&
                                              (tim.closed === false ||
                                                tim.closed === undefined)
                                          ) ? (
                                            <>
                                              <div className="flex-grow-1 mx-3">
                                                <div className="my-1">
                                                  Click all slots below
                                                </div>
                                                <Select
                                                  mode="multiple"
                                                  placeholder="Select Time Slots "
                                                  value={selectedItems}
                                                  onChange={(d) =>
                                                    timeHanlder(d, selectedDate)
                                                  }
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                  options={filteredOptionss.map(
                                                    (item) => ({
                                                      value: item,
                                                      label: item,
                                                    })
                                                  )}
                                                />
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                        {/* <div className="d-flex justify-content-center align-items-center pt-1">
                                          <div className="mx-4">
                                            <label htmlFor="checkboxName">
                                              Closed
                                            </label>
                                            <input
                                              type="checkbox"
                                              name="checkboxName"
                                              id="checkboxName"
                                              className="mx-1"
                                            />
                                          </div>
                                          <div className="flex-grow-1 mx-3">
                                            <Select
                                              mode="multiple"
                                              placeholder="Click to add Time Slots "
                                              value={selectedItems}
                                              onChange={(d) =>
                                                timeHanlder(d, selectedDate)
                                              }
                                              style={{
                                                width: "100%",
                                              }}
                                              options={filteredOptionss.map(
                                                (item) => ({
                                                  value: item,
                                                  label: item,
                                                })
                                              )}
                                            />
                                          </div>
                                        </div> */}
                                      </div>
                                    )
                                  : null}
                              </div>
                            )}
                          </td>
                        );
                      })}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CalenderCus;
